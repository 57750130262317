import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

const url = '/api/v1/internal-service/employee/me';
const updateProfilePicUrl = '/api/v1/internal-service/employee/profile-picture';
const updateEmployeeUrl = '/api/v1/internal-service/employee/update/';

export const GetProfileApi = createApi({
  reducerPath: 'GetProfileApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['GetProfile'],
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => {
        return { url, method: 'GET' };
      },
      providesTags: ['GetProfile'],
    }),
    updateProfilePic: builder.mutation({
      query: (body) => {
        return { url: updateProfilePicUrl, method: 'PUT', body };
      },
    }),
    deleteProfilePic: builder.mutation({
      query: () => {
        const url = updateProfilePicUrl;
        return { url, method: 'DELETE' };
      },
    }),
    updateEmployee: builder.mutation({
      query: ({ userId, data }) => {
        return { url: `${updateEmployeeUrl}${userId}`, method: 'POST', body: data };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetProfile']),
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfilePicMutation,
  useDeleteProfilePicMutation,
  useUpdateEmployeeMutation,
} = GetProfileApi;

export default GetProfileApi.reducer;
