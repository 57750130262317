import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import './internalServerError.scss';

const InternalServerError = ({ error }) => {
  const isChunkLoadError =
    error?.message?.includes('Loading chunk') || error?.name === 'ChunkLoadError';

  if (isChunkLoadError) {
    window.location.reload();
    return (
      <div className='internal-error-wrapper'>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className='internal-error-wrapper'>
      <SettingsSuggestIcon sx={{ fontSize: 196 }} />
      <div className='error-header'>Moo has updated</div>
      <div className='error-message'>
        Moo has updated in the background to improve your experience.
      </div>
      <Button onClick={() => window.location.reload()} color='primary' variant='contained'>
        Click here to reload
      </Button>
    </div>
  );
};

export default InternalServerError;
