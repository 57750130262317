import loadable from '@loadable/component';

export const routesWithOutNav = [
  {
    name: 'signIn',
    route: '/sign-in',
    protected: false,
    component: loadable(() => import('../Pages/Auth/SignIn')),
  },
  {
    name: 'signUp',
    route: '/sign-up',
    protected: false,
    component: loadable(() => import('../Components/Common/NavigateToSingInPage')),
  },
  {
    name: 'forgotPassword',
    route: '/forgot-password',
    protected: false,
    component: loadable(() => import('../Components/Common/NavigateToSingInPage')),
  },
  {
    name: 'resetPassword',
    route: '/employee-reset-password',
    protected: false,
    component: loadable(() => import('../Components/Common/NavigateToSingInPage')),
  },
  {
    name: 'resetPasswordSuccess',
    route: '/reset-password-success',
    protected: false,
    component: loadable(() => import('../Components/Common/NavigateToSingInPage')),
  },
  {
    name: 'twofaSetup',
    route: '/setup-2fa',
    protected: false,
    component: loadable(() => import('../Pages/Auth/TwofaSetup')),
  },
  {
    name: 'twofaOtp',
    route: '/otp-2fa',
    protected: false,
    component: loadable(() => import('../Pages/Auth/TwofaOTP')),
  },
  {
    name: '404',
    route: '*',
    protected: false,
    component: loadable(() => import('../Pages/4O4.jsx')),
  },
];

const routes = [
  {
    name: 'index',
    route: '/',
    protected: true,
    component: loadable(() => import('../Pages/index')),
  },
  {
    name: 'manage-users',
    route: '/vi-er-management/manage-users',
    protected: true,
    component: loadable(() => import('../Pages/Settings/UserManagement/index')),
  },
  {
    name: 'manage-users-group',
    route: '/vi-er-management/manage-users-group',
    protected: true,
    component: loadable(() =>
      import('../Pages/Settings/UserGroupManagement/UserGroupManagementView'),
    ),
  },
  {
    name: 'partner-management',
    route: '/registration/manage-partners',
    protected: true,
    component: loadable(() => import('../Pages/PartnerManagement/index')),
  },
  {
    name: 'batch-management',
    route: '/registration/manage-batches',
    protected: true,
    component: loadable(() => import('../Pages/PartnerManagement/Components/BatchManagement')),
  },
  {
    name: 'batch-list',
    route: '/batch-list',
    protected: true,
    component: loadable(() => import('../Pages/PartnerManagement/BatchList')),
  },
  {
    name: 'user-group',
    route: '/internal-user-management/user-group',
    protected: true,
    component: loadable(() => import('../Pages/Settings/UserGroup/UserGroup')),
  },
  {
    name: 'assign-ae',
    route: '/registration/assign-ae',
    protected: true,
    component: loadable(() => import('../Pages/InternManagement/AssignAE')),
  },
  {
    name: 'interns-detail',
    route: '/registration/manage-interns-detail',
    protected: true,
    component: loadable(() => import('../Pages/InternManagement/index')),
  },
  {
    name: 'manage-intern-application',
    route: '/registration/manage-intern-profiles',
    protected: true,
    component: loadable(() => import('../Pages/InternManagement/ManageInternApplication')),
  },

  {
    name: 'manage-intern-candidature',
    route: '/matching/manage-candidature',
    protected: true,
    component: loadable(() =>
      import('../Pages/HostCompanyManagement/ManagePlacement/ManagePlacements'),
    ),
  },
  {
    name: 'manage-intern-candidature',
    route: '/matching/manage-candidature/:candidatureId',
    protected: true,
    component: loadable(() =>
      import('../Pages/HostCompanyManagement/ManagePlacement/ManageCandidature/ManageCandidature'),
    ),
  },
  {
    name: 'manage-intern-candidature',
    route: '/host-company-management/add-candidature/:internId',
    protected: true,
    component: loadable(() =>
      import('../Pages/HostCompanyManagement/ManagePlacement/AddCandidature/AddCandidature'),
    ),
  },
  {
    name: 'manage-intern-application-video',
    route: '/registration/manage-intern-videos',
    protected: true,
    component: loadable(() => import('../Pages/InternManagement/ManageInternVideoApplication')),
  },
  {
    name: 'manage-weekly-feedback-intern',
    route: '/during-internships/manage-weekly-feedback-intern',
    protected: true,
    component: loadable(() => import('../Pages/InternManagement/WeeklyFeedback')),
  },
  {
    name: 'manage-internship',
    route: '/during-internships/manage-internship',
    protected: true,
    component: loadable(() =>
      import('../Pages/InternManagement/ManageInternship/ManageInternship'),
    ),
  },
  {
    name: 'profile',
    route: '/profile',
    protected: true,
    component: loadable(() => import('../Pages/Profile')),
  },
  {
    name: 'signup-login-setting',
    route: '/global-setting/signup-login',
    component: loadable(() => import('../Pages/GlobalSetting/SingupLoginSetting')),
  },
  {
    name: 'manage-reasons',
    route: '/global-setting/manage-reasons',
    protected: true,
    component: loadable(() => import('../Pages/GlobalSetting/ManageReasons/ManageReasons')),
  },
  {
    name: 'manage-hc-application',
    route: '/registration/manage-hc-applications',
    component: loadable(() => import('../Pages/HostCompanyManagement/ApproveHostCompanies')),
  },
  {
    name: 'pam-application',
    route: '/registration/application-list',
    component: loadable(() => import('../Pages/PartnerManagement/ManageApplicationPAM.jsx')),
  },
  {
    name: 'host-company-details',
    route: '/matching/host-company-details',
    component: loadable(() => import('../Pages/HostCompanyManagement/HostCompanyDetails')),
  },
  {
    name: 'create-host-company',
    route: '/host-company-management/create-host-company',
    component: loadable(() => import('../Pages/HostCompanyManagement/CreateCompany/CreateCompany')),
  },
  {
    name: 'internship-requirement',
    route: '/matching/internship-requirement',
    component: loadable(() => import('../Pages/HostCompanyManagement/ManageInternshipOpportunity')),
  },
  {
    name: 'manage-interviews',
    route: '/matching/manage-interviews',
    component: loadable(() =>
      import('../Pages/HostCompanyManagement/ManageInterview/ManageInterviews'),
    ),
  },
  {
    name: 'edit-interview',
    route: '/host-company-management/edit-interview',
    component: loadable(() =>
      import('../Pages/HostCompanyManagement/ManageInterview/Components/EditInterview'),
    ),
  },
  {
    name: 'manage-weekly-feedback-hc',
    route: '/during-internships/manage-weekly-feedback-hc',
    protected: true,
    component: loadable(() => import('../Pages/HostCompanyManagement/WeeklyFeedback')),
  },
  {
    name: 'offer-details',
    route: '/matching/offer-details',
    component: loadable(() => import('../Pages/HostCompanyManagement/ManageOffers')),
  },
  {
    name: 'manage-recommendation',
    route: 'matching/manage-recommendation',
    component: loadable(() => import('../Pages/HostCompanyManagement/ManageRecommendation')),
  },
  {
    name: 'access',
    route: '/support/access',
    component: loadable(() => import('../Pages/Access/Access')),
  },
  {
    name: 'email change',
    route: '/support/update-user',
    component: loadable(() => import('../Pages/ChangeEmail/ChangeEmail')),
  },
  {
    name: 'delete-user',
    route: '/support/delete-user',
    component: loadable(() => import('../Pages/Support/Support')),
  },
  {
    name: 'view-internship',
    route: '/matching/internship-requirement/view-internship/:ioId',
    component: loadable(() =>
      import('../Components/HostCompanyManagement/InternshipRequirement/ViewInternshipRequirement'),
    ),
  },

  {
    name: 'create-internship',
    route: '/matching/internship-requirement/create-internship',
    component: loadable(() =>
      import('../Components/HostCompanyManagement/InternshipRequirement/ViewInternshipRequirement'),
    ),
  },
  {
    name: 'batch-edit',
    route: '/registration/batch-detail',
    component: loadable(() => import('../Components/BatchModal/CreateEditBatch.jsx')),
  },
  {
    name: 'create-offer',
    route: '/matching/offer-details/create-offer',
    component: loadable(() => import('../Pages/HostCompanyManagement/CreateOffer')),
  },
];

export default routes;
