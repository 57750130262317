import { createApi } from '@reduxjs/toolkit/query/react';
import { onQueryStartedErrorToast } from 'Util/rtkQueryErrorHandler';
import { axiosBaseQuery } from '../../../Axios/AxiosInstance';

const isAttendUrl = '/api/v1/internal-service/interview-feedback/is-attend';
const createFeedbackUrl = '/api/v1/internal-service/interview-feedback/create';
const feedbackOptionUrl = '/common-services/feedback-reasons/list';
const getInterviewByIdUrl = 'api/v1/internal-service/host-company/interview';
const interviewTimelineUrl = 'api/v1/internal-service/host-company/interview';

export const InterviewApi = createApi({
  reducerPath: 'InterviewApi',
  tagTypes: ['GetInterviewDetailsById', 'GetInterviewTimeline'],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    isAttended: builder.mutation({
      query: (data) => {
        return { url: isAttendUrl, method: 'POST', data };
      },
      invalidatesTags: (_, error) => (error ? [] : ['GetInterviewDetailsById']),
    }),
    createFeedback: builder.mutation({
      query: (data) => {
        return { url: createFeedbackUrl, method: 'POST', data };
      },
      invalidatesTags: (_, error) => (error ? [] : ['GetInterviewDetailsById']),
    }),
    getFeedbackOptions: builder.query({
      query: () => {
        return { url: feedbackOptionUrl, method: 'GET' };
      },
    }),
    getInterviewById: builder.query({
      query: ({ introductionId }) => {
        return { url: `${getInterviewByIdUrl}/${introductionId}`, method: 'GET' };
      },
      providesTags: ['GetInterviewDetailsById'],
    }),
    getInterviewTimeline: builder.query({
      query: ({ introductionId }) => {
        return {
          url: `${interviewTimelineUrl}/${introductionId}/interview-timeline`,
          method: 'GET',
        };
      },
      providesTags: ['GetInterviewTimeline'],
      onQueryStarted: onQueryStartedErrorToast,
    }),
  }),
});

export const {
  useIsAttendedMutation,
  useCreateFeedbackMutation,
  useGetFeedbackOptionsQuery,
  useGetInterviewByIdQuery,
  useGetInterviewTimelineQuery,
} = InterviewApi;
export default InterviewApi.reducer;
