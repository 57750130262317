import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';
import { getParamObjectModifier, objectToQueryString } from '../../../Util/utility';

const getNotPlacedApprovedInternURL = '/api/v1/internal-service/intern/unplaced';
const getAvailableHostCompaniesUrl = '/api/v1/internal-service/host-company/available';
const getAvailableIRsUrl = '/api/v1/internal-service/io/reco';
const internRecommendationUrl = '/api/v1/internal-service/recommendation/';
const reviewRecommendationsUrl = '/api/v1/internal-service/recommendation/review';
const createRecommendationUrl = '/api/v1/internal-service/recommendation';

export const ManageRecommendationsApi = createApi({
  reducerPath: 'ManageRecommendationsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['unplacedInterns', 'internRecommendations', 'availableHostCompanies', 'availableIrs'],
  endpoints: (builder) => ({
    getNotPlacedApprovedInterns: builder.query({
      query: (data) => {
        if (data) {
          const modifiedParam = getParamObjectModifier(data);
          let queryString = objectToQueryString(modifiedParam);
          let updatedUrl = queryString
            ? `${getNotPlacedApprovedInternURL}?${queryString}`
            : getNotPlacedApprovedInternURL;
          return { url: updatedUrl, method: 'GET' };
        }
      },
      providesTags: ['unplacedInterns'],
    }),
    getAvailableHostCompanies: builder.query({
      query: (data) => {
        const modifiedParam = getParamObjectModifier(data);
        const queryString = objectToQueryString(modifiedParam);
        const updatedUrl = queryString
          ? `${getAvailableHostCompaniesUrl}?${queryString}`
          : getAvailableHostCompaniesUrl;
        return { url: updatedUrl, method: 'GET' };
      },
      providesTags: ['availableHostCompanies'],
    }),
    getAvailableIRs: builder.query({
      query: (data) => {
        const modifiedParam = getParamObjectModifier(data);
        const queryString = objectToQueryString(modifiedParam);
        const updatedUrl = queryString
          ? `${getAvailableIRsUrl}?${queryString}`
          : getAvailableIRsUrl;
        return { url: updatedUrl, method: 'GET' };
      },
      providesTags: ['availableIrs'],
    }),
    getInternRecommendations: builder.query({
      query: ({ internId, pageNumber, perPage }) => {
        return {
          url: `${internRecommendationUrl}${internId}/summary?pageNumber=${pageNumber}&perPage=${perPage}&sortColumn=lastRecommendedOn&sortOrder=DESC`,
          method: 'GET',
        };
      },
      providesTags: ['internRecommendations'],
    }),

    reviewRecommendations: builder.mutation({
      query: (data) => {
        return {
          url: reviewRecommendationsUrl,
          method: 'POST',
          body: data,
        };
      },
    }),
    createRecommendations: builder.mutation({
      query: (data) => {
        return {
          url: createRecommendationUrl,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: [
        'unplacedInterns',
        'internRecommendations',
        'availableIrs',
        'availableHostCompanies',
      ],
    }),
  }),
});

export const {
  useGetAvailableHostCompaniesQuery,
  useGetAvailableIRsQuery,
  useGetInternRecommendationsQuery,
  useReviewRecommendationsMutation,
  useCreateRecommendationsMutation,
  useGetNotPlacedApprovedInternsQuery,
  usePrefetch,
} = ManageRecommendationsApi;
export default ManageRecommendationsApi.reducer;
