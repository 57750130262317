import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { getLoginState } from './Redux/Slices/User/Login';
import { getUserProfile } from './Redux/Slices/User/GetUserProfile';
import WithoutNav from './Util/WithoutNav';
import WithNav from './Util/WithNav';
import routes, { routesWithOutNav } from './Util/routes';
import { loadLocales } from './Util/locale';
import './assets/scss/style.scss';
import 'antd/dist/antd.min.css';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from '@mui/material';
import muiTheme from './muiTheme';
import GlobalPopups from './Components/Common/Mui/GlobalPopups';
import InternalServerError from './Components/InternalServerError';
import { initializeClarity } from './Clarity';

function NavigatePath() {
  let location = useLocation();
  return <Navigate to={'/sign-in'} state={{ prevPath: location.pathname }} />;
}
function App() {
  const dispatch = useDispatch();
  const [initIntl, setInitIntl] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const loginState = useSelector(getLoginState);

  const handleStorageUpdate = () => {
    const isEmployeeLoggedIn = !!localStorage.getItem('EMPLOYEE_ID');
    isEmployeeLoggedIn && dispatch(getUserProfile());
    setIsAuthenticated(isEmployeeLoggedIn);
  };

  useEffect(() => {
    if (process.env.REACT_APP_CLUSTER === 'prod') {
      initializeClarity();
    }
  }, []);

  useEffect(() => {
    handleStorageUpdate();
    window.addEventListener('storage', () => handleStorageUpdate);
    return () => window.removeEventListener('storage', handleStorageUpdate);
  }, [loginState?.isLoggedIn]);

  useEffect(() => {
    async function fetchData() {
      const initLocale = await loadLocales();
      setInitIntl(initLocale);
    }

    fetchData();
  }, []);

  const renderRoute = (route) => (
    <Route exact key={route?.name} path={route?.route} element={<route.component />} />
  );

  const renderProtectedRoute = (route) => {
    return (
      <Route
        key={route?.name}
        path={route.route}
        element={isAuthenticated ? <route.component /> : <NavigatePath />}
      />
    );
  };
  return (
    <>
      {initIntl && (
        <ThemeProvider theme={muiTheme}>
          <Sentry.ErrorBoundary fallback={<InternalServerError />}>
            <Router>
              <Routes>
                <Route element={<WithoutNav />}>
                  {routesWithOutNav?.map((route) => renderRoute(route))}
                </Route>
                <Route element={<WithNav />}>
                  {routes?.map((route) => renderProtectedRoute(route))}
                </Route>
              </Routes>
            </Router>
            <GlobalPopups />
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      )}
    </>
  );
}

export default Sentry.withProfiler(App);
