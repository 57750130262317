import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios';

const url = '/api/v1/internal-service/host-company/interview/intern/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getInternDetailsByEmail = createAsyncThunk(
  'getInternDetailsByEmail',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(url + request?.email)
      .then(({ data: { data: response } }) => {
        return response;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternDetailsByEmail = createSlice({
  name: 'GetInternDetailsByEmail',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getInternDetailsByEmail.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInternDetailsByEmail.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload;
      })
      .addCase(getInternDetailsByEmail.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInternDetailsByEmailState = (state) => state.GetInternDetailsByEmail;
export const { reset } = GetInternDetailsByEmail.actions;
export default GetInternDetailsByEmail.reducer;
