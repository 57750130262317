import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';
import { getParamObjectModifier } from '../../../Util/utility';

const VIDEO_URL = '/api/v1/internal-service/intern/video';
const FEEDBACK_REASON_URL = '/common-services/feedback-reasons/video';

export const ManageVideoApplicationApi = createApi({
  reducerPath: 'manageVideoApplicationApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['applicationDetails', 'getVideos'],

  endpoints: (builder) => ({
    getVideos: builder.query({
      query: (request = {}) => {
        const modifiedParam = getParamObjectModifier(request);
        return {
          url: VIDEO_URL,
          method: 'GET',
          body: modifiedParam,
        };
      },
      providesTags: ['getVideos'],
    }),
    getVideoQuestion: builder.query({
      query: (internId) => {
        return { url: `${VIDEO_URL}/${internId}`, method: 'GET', body: null };
      },
      providesTags: ['applicationDetails'],
    }),
    rateVideo: builder.mutation({
      query: (body) => {
        return { url: `${VIDEO_URL}/rating`, method: 'POST', body };
      },
      invalidatesTags: (result, error) => (error ? [] : ['applicationDetails']),
    }),
    provideFeedback: builder.mutation({
      query: (body) => {
        return { url: `${VIDEO_URL}/feedback`, method: 'POST', body };
      },
      invalidatesTags: (result, error) => (error ? [] : ['applicationDetails']),
    }),
    shareFeedback: builder.mutation({
      query: (body) => {
        return { url: `${VIDEO_URL}/submit-feedback`, method: 'POST', body };
      },
      invalidatesTags: (result, error) => (error ? [] : ['applicationDetails', 'getVideos']),
    }),
    getVideoFeedbackReasons: builder.query({
      query: () => {
        return { url: FEEDBACK_REASON_URL, method: 'GET', body: null };
      },
    }),
    updateVideoVisibility: builder.mutation({
      query: (body) => {
        return { url: `${VIDEO_URL}/update-visibility`, method: 'PATCH', body };
      },
      invalidatesTags: (result, error) => (error ? [] : ['applicationDetails']),
    }),
  }),
});

export const {
  useGetVideosQuery,
  useGetVideoQuestionQuery,
  useRateVideoMutation,
  useProvideFeedbackMutation,
  useShareFeedbackMutation,
  useGetVideoFeedbackReasonsQuery,
  useUpdateVideoVisibilityMutation,
  usePrefetch,
} = ManageVideoApplicationApi;
export default ManageVideoApplicationApi.reducer;
