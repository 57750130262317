import loginReducer from '../Slices/User/Login';
import userListReducer from '../Slices/User/GetAllUsers';
import userInviteReducer from '../Slices/User/InviteUser';
import sendRemainderReducer from '../Slices/User/SendRemainder';
import updateUserStatusReducer from '../Slices/User/UpdateUserStatus';
import getUserProfileReducer from '../Slices/User/GetUserProfile';
import getUserGroupsReducer from '../Slices/UserGroup/GetUserGroups';
import getTeamsReducer from '../Slices/Common/GetTeams';
import resetPasswordReducer from '../Slices/User/ResetPassword';
import copyGroupReducer from '../Slices/UserGroup/CopyGroup';
import getPermissionsReducer from '../Slices/Common/GetPermissionsMaster';
import createUserGroupReducer from '../Slices/UserGroup/CreateGroup';
import getGroupByIDReducer from '../Slices/UserGroup/GetGroupByID';
import editUserGroupReducer from '../Slices/UserGroup/EditGroup';
import updatePartnerStatusReducer from '../Slices/Partner/UpdatePartnerStatus';
import createPartnerReducer from '../Slices/Partner/CreatePartner';
import updatePartnerReducer from '../Slices/Partner/UpdatePartner';
import getPartnerByIDReducer from '../Slices/Partner/GetPartnerByID';
import createBatchSignUpReducer from '../Slices/Batch/CreateBatchSignUp';

import createBatchStepOneReducer from '../Slices/Batch/CreateBatchStepOne';
import createBatchStepTwoReducer from '../Slices/Batch/CreateBatchStepTwo';
import createBatchStepThreeReducer from '../Slices/Batch/CreateBatchStepThree';
import createBatchStepFourReducer from '../Slices/Batch/CreateBatchStepFour';
import fileUploadReducer from '../Slices/Common/UploadFile';
import fileUploads3Reducer from '../Services/UploadFileS3';
import batchListReducer from '../Slices/Batch/GetBatchList';
import batchByIdReducer from '../Slices/Batch/GetBatchByID';
import deleteFileReducer from '../Slices/Common/DeleteUploadedFile';
import batchStatusReducer from '../Slices/Batch/UpdateBatchStatus';
import updateBatchStepOneReducer from '../Slices/Batch/UpdateBatchStepOne';
import updateBatchStepTwoReducer from '../Slices/Batch/UpdateBatchStepTwo';
import updateBatchStepThreeReducer from '../Slices/Batch/UpdateBatchStepThree';
import updateBatchStepFourReducer from '../Slices/Batch/UpdateBatchStepFour';
import updateBatchSignUpReducer from '../Slices/Batch/UpdateBatchSignUp';

import getBatchListSubPageReducer from '../Slices/Batch/GetBatchListSubPage';
import getUserByIdReducer from '../Slices/User/GetUserByID';
import updateUserByIdReducer from '../Slices/User/UpdateUserByID';
import internByIdReducer from '../Slices/Intern/GetInternByID';
import internListReducer from '../Slices/Intern/GetInternList';
import { GetApplicationPAMApi } from '../Slices/Intern/GetApplicationPAM';

import internListAEReducer from '../Slices/Intern/GetInternListAE';

import { GetFullInternListApi } from '../Slices/Intern/GetFullInternList';
import internApplicationReducer from '../Slices/Intern/GetInternApplication';
import batchWiseInternListReducer from '../Slices/Intern/GetInternListBatchWise';
import approveInternBatchReducer from '../Slices/Intern/ApproveInternBatch';
import assignIEMBulkReducer from '../Slices/Intern/AssignIEMBulk';
import internByPartnerTypeReducer from '../Slices/Intern/GetInternByPartnerType';
import internEmpDetailsReducer from '../Slices/Intern/GetInternEmployementDetails';
import removeIEMBulkReducer from '../Slices/Intern/RemoveIEMBulk';
import updateInternBatchReducer from '../Slices/Intern/UpdateInternBatch';
import { notesReducer } from './notesReducer';
import getEmailLogsReducer from '../Slices/Common/GetEmailLogs';
import getSystemLogsReducer from '../Slices/Common/GetSystemLogs';
import createSystemLogsReducer from '../Slices/Common/CreateSystemLogs';
import getIEMReducer from '../Slices/Intern/GetIEMListSlice';
import checkBatchNameReducer from '../Slices/Batch/CheckBatchName';
import getSendEmailReducer from '../Slices/Common/SendEmailGeneral';
import getInternshipDatesReducer from '../Slices/Batch/GetInternshipDates';
import getInternshipDurationsReducer from '../Slices/Batch/GetInternshipDurations';
import getInternshipHoursReducer from '../Slices/Batch/GetInternshipHours';
import getCareerFieldsReducer from '../Slices/Batch/GetCareerFields';
import getGenderFieldsReducer from '../Slices/Batch/GetGenderFields';
import getInternshipLocationsReducer from '../Slices/Batch/GetInternshipLocations';
import getCustomFormsReducer from '../Slices/Batch/GetCustomForms';
import getServiceProviderLists from '../Slices/SignupLoginSetting/GetServiceProviderList';
import getSignupLoginSetting from '../Slices/SignupLoginSetting/GetSignupLoginSetting';
import updateGlobalSetting from '../Slices/SignupLoginSetting/UpdateSignupLoginSetting';
import createGlobalSetting from '../Slices/SignupLoginSetting/CreateGlobalSetting';
import getCareerFieldDetail from '../Slices/CareerField/GetCareerFieldDetail';
import getInstituteLists from '../Slices/SignupLoginSetting/GetInstituteList';
import markAsAliasInstitute from '../Slices/SignupLoginSetting/MarkAsAliasInstitute';
import getReviewedInstitute from '../Slices/SignupLoginSetting/GetReviewedInstitute';
import getInstituteDetail from '../Slices/SignupLoginSetting/GetInstituteDetail';
import updateInstituteDetail from '../Slices/SignupLoginSetting/UpdateInstituteDetail';
import markAsReviewedInstitute from '../Slices/SignupLoginSetting/MarkAsReviewed';
import getCAMList from '../Slices/HostCompany/GetCAMList';
import getCompanyListPAMWise from '../Slices/HostCompany/GetCompanyListPAMWise';
import bulkAssignPAM from '../Slices/HostCompany/BulkAssignPAM';
import bulkAssignPM from '../Slices/HostCompany/BulkAssignPM';
import getHostCompanyList from '../Slices/HostCompany/GetHostCompanyList';
import getHostCompanyDetail from '../Slices/HostCompany/GetHostCompanyDetail';
import updateHostCompanyStatus from '../Slices/HostCompany/UpdateHostCompanyStatus';
import getMatchedInterns from '../Slices/Intern/GetMatchedInterns';
import getMatchedInternsDetail from '../Slices/Intern/GetMatchedInternsDetail';
import sendReminderToSelectInterviewSlot from '../Slices/Intern/SendReminderToSelectInterviewSlot';
import sendReminderInterviewSlotState from '../Slices/Intern/SendReminderInterview';
import updateInterviewSlot from '../Slices/Intern/UpdateSuggestedSlot';
import getInternQuestionAnswer from '../Slices/Intern/GetInternQuestionAnswer';
import updateInterviewAnswer from '../Slices/Intern/UpdateSuggestedAnswer';
import editQuestionAnswer from '../Slices/Intern/EditQuestionAnswer';
import updatePrefferedName from '../Slices/Intern/UpdatePrefferedName';
import updateCitizenship from '../Slices/Intern/UpdateCitizenship';
import updatePhoneNumberAndAltEmail from '../Slices/Intern/UpdatePhoneNumberAndAltEmail';
import updateNameAndDob from '../Slices/Intern/UpdateNameDob';
import updateFirstGen from '../Slices/Intern/UpdateFirstGen';
import updateIsInternationalStudent from '../Slices/Intern/UpdateIsInternationalStudent';
import updateInternshipGoal from '../Slices/Intern/UpdateInternshipGoal';
import updateJobTitle from '../Slices/Intern/UpdateApplicationJobTitle';
import updateSkillsToImprove from '../Slices/Intern/UpdateSkillsToImprove';
import updateSkillsToLearn from '../Slices/Intern/UpdateSkillsToLearn';
import updateAllProjects from '../Slices/Intern/UpdateAllProjects';
import updateApplicationCareerField from '../Slices/Intern/UpdateApplicationCareerField';
import updatePreferredCountry from '../Slices/Intern/UpdatePreferredCountry';
import updateResidingCountry from '../Slices/Intern/UpdateResidingCountry';
import updatePreferredStartDate from '../Slices/Intern/UpdatePreferredStartDate';
import updateInternshipDuration from '../Slices/Intern/UpdateInternshipDuration';
import updateEnglishProficiency from '../Slices/Intern/UpdateEnglishProficiency';
import updateLowEnglishProficiency1 from '../Slices/Intern/UpdateLowEnglishProficiency1';
import updateLowEnglishProficiency2 from '../Slices/Intern/UpdateLowEnglishProficiency2';
import updateLowEnglishProficiency3 from '../Slices/Intern/UpdateLowEnglishProficiency3';
import updateLowEnglishProficiency4 from '../Slices/Intern/UpdateLowEnglishProficiency4';
import updateHighestEduOrCert from '../Slices/Intern/UpdateHighestEducationOrCertificaiton';
import updateDegreeOfficialTitle from '../Slices/Intern/UpdateDegreeOfficialTitle';
import updateInternshipHours from '../Slices/Intern/UpdateInternshipHours';
import updateRacialGroup from '../Slices/Intern/UpdateRacialGroup';
import suggestQuestionChanges from '../Slices/Intern/SuggestQuestionChanges';
import getSuggestionList from '../Slices/Intern/GetSuggestionList';
import createReasonForEditReducer from '../Slices/Intern/CreateReasonForEditApplication';
import createWeeklyCustomProjectReducer from '../Slices/Intern/CreateWeeklyCustomProject';
import approveQuestionAndAnswerReducer from '../Slices/Intern/ApproveQuestionAnswer';
import createMassSystemLogs from '../Slices/Common/CreateMassSystemLogs';
import { GetBasicDetails } from '../Slices/HostCompany/GetBasicDetails';
import { GetAdditionalInfo } from '../Slices/HostCompany/GetAdditionalInfo';
import getCompanyPOCsReducer from '../Slices/HostCompany/GetCompanyPOCs';
import getInternshipPlansReducer from '../Slices/HostCompany/GetInternshipPlans';
import { GetSocialMediaDetails } from '../Slices/HostCompany/GetSocialMediaDetails';
import getCompanyDetailsByIDReducer from '../Slices/HostCompany/GetCompanyDetailsById';
import getHostCompanyTableListReducer from '../Slices/HostCompany/GetHostCompanyTableList';
import createCompanyNotesReducer from '../Slices/HCNotes/CreateNotes';
import deleteCompanyNotesReducer from '../Slices/HCNotes/DeleteNote';
import updateCompanyNotesReducer from '../Slices/HCNotes/UpdateNotes';
import getCompanyNotesReducer from '../Slices/HCNotes/GetNotes';
import getHCEmailLogsReducer from '../Slices/HostCompany/GetCompanyEmailLogs';
import getInternMatchesReducer from '../Slices/HostCompany/GetInternMatches';
import getAasReducer from '../Slices/Intern/GetAasList';
import assignBulkInternsToAasReducer from '../Slices/Intern/AssignBulkInternsToAas';
import getInternsListByAAsReducer from '../Slices/Intern/GetInternsListByAAs';
import sendReminderReducer from '../Slices/User/SendReminderPayment';
import approveInternApplicationReducer from '../Slices/Intern/ApproveInternApplication';
import getInternVideosReducer from '../Slices/Intern/GetInternVideos';
import updateInternProfileFeedbackReducer from '../Slices/Intern/UpdateInternProfileFeedback';
import updateInternVideoFeedbackReducer from '../Slices/Intern/UpdateInternVideoFeedback';
import remindInternReducer from '../Slices/Intern/RemindIntern';
import getInternsAcademic from '../Slices/Intern/GetInternsAcademic';
import getInternBatchList from '../Slices/Intern/GetInternBatches';
import getInternApplicationList from '../Slices/Intern/GetInternsApplication';
import getInternCompanyMatches from '../Slices/Intern/GetCompanyMatches';
import getInternCareerPreferences from '../Slices/Intern/GetCareerPreference';
import getInternshipOpportunityList from '../Slices/HostCompany/GetInternshipOppourtunityList';
import getInternshipOpportunityDetail from '../Slices/HostCompany/GetInternshipOpportunityDetail';
import updateIOStatus from '../Slices/HostCompany/UpdateIOStatus';
import getOfferList from '../Slices/Offer/GetOfferList';
import getInterviewList from '../Slices/Interview/GetInterviewList';
import getOfferById from '../Slices/Offer/GetOfferById';
import saveFeedback from '../Slices/Offer/SaveFeedback';
import getFeedback from '../Slices/Offer/GetFeedback';
import updateStatus from '../Slices/Offer/UpdateOfferStatus';
import sendOfferReminder from '../Slices/Offer/SendOfferReminder';
import { GetWeeklyFeedbackApi } from '../Slices/Intern/GetWeeklyFeedback';
import getWeeklyFeedbackByIdReducer, {
  GetWeeklyFeedbackByIdApi,
} from '../Slices/Intern/GetWeeklyFeedbackById';
import updateWeeklyFeedbackCompleteReducer from '../Slices/Intern/UpdateWeeklyFeedbackComplete';
import updateWeeklyQuestionReducer from '../Slices/Intern/UpdateWeeklyQuestion';
import { GetInternProfileDetailsApi } from '../Slices/Intern/GetInternProfileDetails';
import getAutomatedRecommendedProfilesReducer from '../Slices/HostCompany/GetAutomatedRecommendedProfiles';
import { GetApprovedHostCompanyToRecommendApi } from '../Slices/Intern/GetApprovedHostCompanyToRecommend';
import { RecommendInternsToHCApi } from '../Slices/Intern/RecommendInternsToHC';
import { GetManualRecommendedProfilesActiveApi } from '../Slices/HostCompany/GetManualRecommendedProfilesActive';
import { GetManualRecommendedProfilesPastApi } from '../Slices/HostCompany/GetManualRecommendedProfilesPast';
import { BatchNotesApi } from '../Slices/Notes/BatchNotes';
import getVideoQuestionsReducer from '../Slices/ManageApplication/getVideoQuestions';
import getPaymentListReducer from '../Slices/ManageApplication/getPaymentList';
import getListProfileFeedbackReducer from '../Slices/ManageApplication/getListProfileFeedback';
import getB2bInternListReducer from '../Slices/Intern/GetB2bInternList';
import { CheckIfInternCanRecommendApi } from '../Slices/Intern/CheckIfInternCanRecommend';
import getInternDetailsByEmail from '../Slices/Interview/GetInternDetailsByEmailId';
import { GetUniversityApi } from '../Slices/Intern/GetUniversity';
import { GetBatchDetailApi } from '../Slices/Intern/GetBatchDetail';
import { ShortlistedHcsApi } from '../Slices/HostCompany/ManagePlacements/ShortListedHcApi';
import { InternshipOpportunityApi } from '../Slices/InternshipRequirement/InternshipRequirement';
import { GetCandidatureInfoApi } from '../Slices/Intern/GetCandidatureInfo';
import { OffersApi } from '../Slices/Offer/OffersApi';
import { GetBatchListApi } from '../Slices/Batch/GetBatchListRTK';
import { GetApplicationListPAMApi } from '../Slices/PAM/GetApplicationListPAM';
import { GetWeeklyInternDetailsApi } from '../Slices/Intern/GetWeeklyInternDetails';
import { GetWeeklyShortcutsApi } from '../Slices/Intern/GetWeeklyShortcuts';
import { CommonListApis } from '../Slices/Common/GetListApis';
import { GetHCPocs } from '../Slices/HostCompany/GetHCPocs';
import { UpdateHCDetails } from '../Slices/HostCompany/UpdateHCDetails';
import { UpdatePocDetails } from '../Slices/HostCompany/UpdatePocDetails';
import { RevertConfirmationApi } from '../Slices/Interview/RevertConfirmation';
import { DeleteHcPhoto } from '../Slices/HostCompany/DeleteHcPhoto';
import { AuthApi } from '../Slices/Auth/AuthApi';
import { RevertOfferAcceptanceApi } from '../Slices/Offer/RevertAcceptance';
import { ManageInternshipsApi } from '../Slices/Intern/ManageInternships';
import { RevertCandidatureConfirmationApi } from '../Slices/Intern/RevertRejection';
import { ManageVideoApplicationApi } from '../Slices/ManageApplication/manageVideoApplication';
import { PostCustomMailApi } from '../Slices/ManageApplication/postCustomMail';
import { GetBatchCodeListApi } from '../Slices/Batch/GetBatchCodeListRTK';
import { ManagePlacementsApi } from '../Slices/HostCompany/ManagePlacements/ManagePlacementsApi';
import { BlacklistCompanyApi } from '../Slices/HostCompany/BlacklistCompany/BlacklistCompanyApi';
import { GetHcDetailsByIdOrEmailApi } from '../Slices/Interview/GetHcDetailsByIdOrEmailApi';
import { GetAnalyticsDetailsApi } from '../Slices/Dashboard/Analytics';
import { GetInterviewDetailsApi } from '../Slices/Interview/GetInterviewsList';
import { GetInternDetailsByEmailApi } from '../Slices/Interview/GetInternDetailsByEmailIdRTK';
import snackbarReducer from '../Slices/Common/SnackbarSlice';
import { ManageRecommendationsApi } from '../Slices/HostCompany/ManageRecommendations';
import { AssignAEApi } from '../Slices/HostCompany/AssignAE';
import { AssignCamApi } from '../Slices/HostCompany/RtkQuery/AssignCam';
import { HostCompanyTableApi } from '../Slices/HostCompany/RtkQuery/HostCompanyDetailsApi';
import { GetOfferListApi } from '../Slices/Offer/GetOfferListApi';
import { CreateCompanyApi } from '../Slices/HostCompany/CreateCompany';
import { GetCountByStartDateApi } from '../Slices/Batch/GetCountByStartDate';
import { MarkCompanySignupComplete } from '../Slices/HostCompany/MarkCompanySignupComplete';
import { ManagePartnerList } from '../Slices/Partner/GetPartnerList';
import { ManageNotesApi } from '../Slices/GlobalNotes/ManageNotes';
import ManageTagInfo from '../Slices/GlobalNotes/ManageTagInfo';
import { GetProfileApi } from '../Slices/User/GetUserProfileNew';
import { ManageBatchApi } from '../Slices/BatchRtk/ManageBatch';
import ManagePopoverLoading from '../Slices/BatchRtk/ManagePopoverLoading';
import { InterviewApi } from '../Slices/Interview/InterviewApi';

export const rootReducer = {
  Login: loginReducer,
  UserList: userListReducer,
  UserInvite: userInviteReducer,
  SendRemainder: sendRemainderReducer,
  UpdateUser: updateUserStatusReducer,
  UserProfile: getUserProfileReducer,
  UserGroupList: getUserGroupsReducer,
  Teams: getTeamsReducer,
  ResetPassword: resetPasswordReducer,
  CopyUserGroup: copyGroupReducer,
  Permissions: getPermissionsReducer,
  CreateUserGroup: createUserGroupReducer,
  GroupByID: getGroupByIDReducer,
  EditUserGroup: editUserGroupReducer,
  PartnerStatus: updatePartnerStatusReducer,
  CreatePartner: createPartnerReducer,
  UpdatePartner: updatePartnerReducer,
  PartnerByID: getPartnerByIDReducer,
  CreateBatchSignUp: createBatchSignUpReducer,
  CreateBatchStepOne: createBatchStepOneReducer,
  CreateBatchStepTwo: createBatchStepTwoReducer,
  CreateBatchStepThree: createBatchStepThreeReducer,
  CreateBatchStepFour: createBatchStepFourReducer,
  FileUpload: fileUploadReducer,
  FileUploadS3: fileUploads3Reducer,
  BatchList: batchListReducer,
  BatchByID: batchByIdReducer,
  DeleteFile: deleteFileReducer,
  BatchStatus: batchStatusReducer,
  CheckBatchName: checkBatchNameReducer,
  UpdateBatchStepOne: updateBatchStepOneReducer,
  UpdateBatchStepTwo: updateBatchStepTwoReducer,
  UpdateBatchStepThree: updateBatchStepThreeReducer,
  UpdateBatchStepFour: updateBatchStepFourReducer,
  UpdateBatchSignUp: updateBatchSignUpReducer,
  BatchListSubPage: getBatchListSubPageReducer,
  UserByID: getUserByIdReducer,
  UpdateUserByID: updateUserByIdReducer,
  InternByID: internByIdReducer,
  InternList: internListReducer,
  InternListAE: internListAEReducer,
  [GetFullInternListApi.reducerPath]: GetFullInternListApi.reducer,
  InternApplicationList: internApplicationReducer,
  BatchWiseInternList: batchWiseInternListReducer,
  ApproveInternBatch: approveInternBatchReducer,
  AssignIEMBulk: assignIEMBulkReducer,
  InternByPartnerType: internByPartnerTypeReducer,
  InternEmployementDetails: internEmpDetailsReducer,
  RemoveIEMBulk: removeIEMBulkReducer,
  UpdateInternBatch: updateInternBatchReducer,
  ...notesReducer,
  CreateCompanyNotes: createCompanyNotesReducer,
  DeleteCompanyNotes: deleteCompanyNotesReducer,
  UpdateCompanyNotes: updateCompanyNotesReducer,
  NotesByCompanyID: getCompanyNotesReducer,
  EmailLogs: getEmailLogsReducer,
  GetSystemLogs: getSystemLogsReducer,
  CreateSystemLogs: createSystemLogsReducer,
  GetIEM: getIEMReducer,
  SendEmail: getSendEmailReducer,
  GetInternshipDates: getInternshipDatesReducer,
  GetInternshipDurations: getInternshipDurationsReducer,
  GetInternshipHours: getInternshipHoursReducer,
  GetCareerFields: getCareerFieldsReducer,
  GetGenderFields: getGenderFieldsReducer,
  GetInternshipLocations: getInternshipLocationsReducer,
  GetCustomForms: getCustomFormsReducer,
  GetServiceProviderLists: getServiceProviderLists,
  CreateGlobalSetting: createGlobalSetting,
  GetSignupLoginSetting: getSignupLoginSetting,
  UpdateGlobalSetting: updateGlobalSetting,
  GetCareerFieldDetail: getCareerFieldDetail,
  GetInstituteLists: getInstituteLists,
  MarkAsAliasInstitute: markAsAliasInstitute,
  GetReviewedInstitute: getReviewedInstitute,
  GetInstituteDetail: getInstituteDetail,
  UpdateInstituteDetail: updateInstituteDetail,
  MarkAsReviewedInstitute: markAsReviewedInstitute,
  GetCAMList: getCAMList,
  GetCompanyListPAMWise: getCompanyListPAMWise,
  BulkAssignPAM: bulkAssignPAM,
  BulkAssignPM: bulkAssignPM,
  GetHostCompanyList: getHostCompanyList,
  GetHostCompanyDetail: getHostCompanyDetail,
  UpdateHostCompanyStatus: updateHostCompanyStatus,
  GetMatchedInterns: getMatchedInterns,
  GetMatchedInternsDetail: getMatchedInternsDetail,
  SendReminderToSelectInterviewSlot: sendReminderToSelectInterviewSlot,
  SendReminderInterviewSlotState: sendReminderInterviewSlotState,
  UpdateInterviewSlot: updateInterviewSlot,
  GetInternQuestionAnswer: getInternQuestionAnswer,
  UpdateInterviewAnswer: updateInterviewAnswer,
  EditQuestionAnswer: editQuestionAnswer,
  PreferredName: updatePrefferedName,
  PhoneNumberAndAltEmail: updatePhoneNumberAndAltEmail,
  NameAndDob: updateNameAndDob,
  Citizenship: updateCitizenship,
  FirstGen: updateFirstGen,
  IsInternationalStudent: updateIsInternationalStudent,
  InternshipGoal: updateInternshipGoal,
  JobTitle: updateJobTitle,
  SkillsToImprove: updateSkillsToImprove,
  SkillsToLearn: updateSkillsToLearn,
  AllProjects: updateAllProjects,
  ApplicationCareerField: updateApplicationCareerField,
  PreferredCountry: updatePreferredCountry,
  ResidingCountry: updateResidingCountry,
  PreferredStartDate: updatePreferredStartDate,
  InternshipDuration: updateInternshipDuration,
  EnglishProficiency: updateEnglishProficiency,
  LowEnglishProficiency1: updateLowEnglishProficiency1,
  LowEnglishProficiency2: updateLowEnglishProficiency2,
  LowEnglishProficiency3: updateLowEnglishProficiency3,
  LowEnglishProficiency4: updateLowEnglishProficiency4,
  HighestEduOrCert: updateHighestEduOrCert,
  DegreeOfficialTitle: updateDegreeOfficialTitle,
  InternshipHours: updateInternshipHours,
  RacialGroup: updateRacialGroup,
  SuggestQuestionChanges: suggestQuestionChanges,
  GetSuggestionList: getSuggestionList,
  CreateMassSystemLogs: createMassSystemLogs,
  [GetBasicDetails.reducerPath]: GetBasicDetails.reducer,
  [GetAdditionalInfo.reducerPath]: GetAdditionalInfo.reducer,
  GetCompanyPOCs: getCompanyPOCsReducer,
  GetInternshipPlans: getInternshipPlansReducer,
  [GetSocialMediaDetails.reducerPath]: GetSocialMediaDetails.reducer,
  CompanyDetailsByID: getCompanyDetailsByIDReducer,
  GetHostCompanyTableList: getHostCompanyTableListReducer,
  GetHCEmailLogs: getHCEmailLogsReducer,
  GetInternMatches: getInternMatchesReducer,
  CreateReasonForEdit: createReasonForEditReducer,
  CreateCustomProject: createWeeklyCustomProjectReducer,
  ApproveQuestionAndAnswer: approveQuestionAndAnswerReducer,
  GetInternsAcademic: getInternsAcademic,
  GetAas: getAasReducer,
  AssignBulkInternsToAas: assignBulkInternsToAasReducer,
  GetInternsListByAas: getInternsListByAAsReducer,
  SendReminder: sendReminderReducer,
  ApproveInternApplication: approveInternApplicationReducer,
  GetInternVideos: getInternVideosReducer,
  UpdateInternProfileFeedback: updateInternProfileFeedbackReducer,
  UpdateInternVideoFeedback: updateInternVideoFeedbackReducer,
  RemindIntern: remindInternReducer,
  GetInternBatchList: getInternBatchList,
  GetInternApplicationList: getInternApplicationList,
  GetInternCompanyMatches: getInternCompanyMatches,
  GetInternCareerPreferences: getInternCareerPreferences,
  GetInternshipOpportunityList: getInternshipOpportunityList,
  GetInternshipOpportunityDetail: getInternshipOpportunityDetail,
  UpdateIOStatus: updateIOStatus,
  GetOfferList: getOfferList,
  GetInterviewList: getInterviewList,
  GetOfferById: getOfferById,
  SaveFeedback: saveFeedback,
  GetFeedback: getFeedback,
  UpdateOfferStatus: updateStatus,
  SendOfferReminder: sendOfferReminder,
  GetWeeklyFeedbackById: getWeeklyFeedbackByIdReducer,
  UpdateWeeklyFeedbackComplete: updateWeeklyFeedbackCompleteReducer,
  UpdateWeeklyQuestion: updateWeeklyQuestionReducer,
  GetAutomatedRecommendedProfiles: getAutomatedRecommendedProfilesReducer,
  GetVideoQuestion: getVideoQuestionsReducer,
  GetPaymentList: getPaymentListReducer,
  GetListProvideFeedback: getListProfileFeedbackReducer,
  GetB2bInternList: getB2bInternListReducer,
  GetInternDetailsByEmail: getInternDetailsByEmail,
  ManageTagInfo: ManageTagInfo,
  ManagePopoverLoading: ManagePopoverLoading,
  [GetApprovedHostCompanyToRecommendApi.reducerPath]: GetApprovedHostCompanyToRecommendApi.reducer,
  [RecommendInternsToHCApi.reducerPath]: RecommendInternsToHCApi.reducer,
  [GetManualRecommendedProfilesActiveApi.reducerPath]:
    GetManualRecommendedProfilesActiveApi.reducer,
  [CheckIfInternCanRecommendApi.reducerPath]: CheckIfInternCanRecommendApi.reducer,
  [GetManualRecommendedProfilesPastApi.reducerPath]: GetManualRecommendedProfilesPastApi.reducer,
  [BatchNotesApi.reducerPath]: BatchNotesApi.reducer,
  [GetUniversityApi.reducerPath]: GetUniversityApi.reducer,
  [GetBatchDetailApi.reducerPath]: GetBatchDetailApi.reducer,
  [GetCandidatureInfoApi.reducerPath]: GetCandidatureInfoApi.reducer,
  [ShortlistedHcsApi.reducerPath]: ShortlistedHcsApi.reducer,
  [InternshipOpportunityApi.reducerPath]: InternshipOpportunityApi.reducer,
  [OffersApi.reducerPath]: OffersApi.reducer,
  [GetBatchListApi.reducerPath]: GetBatchListApi.reducer,
  [GetApplicationListPAMApi.reducerPath]: GetApplicationListPAMApi.reducer,
  [GetWeeklyFeedbackApi.reducerPath]: GetWeeklyFeedbackApi.reducer,
  [GetWeeklyInternDetailsApi.reducerPath]: GetWeeklyInternDetailsApi.reducer,
  [GetWeeklyFeedbackByIdApi.reducerPath]: GetWeeklyFeedbackByIdApi.reducer,
  [GetWeeklyShortcutsApi.reducerPath]: GetWeeklyShortcutsApi.reducer,
  [CommonListApis.reducerPath]: CommonListApis.reducer,
  [GetHCPocs.reducerPath]: GetHCPocs.reducer,
  [UpdateHCDetails.reducerPath]: UpdateHCDetails.reducer,
  [UpdatePocDetails.reducerPath]: UpdatePocDetails.reducer,
  [RevertConfirmationApi.reducerPath]: RevertConfirmationApi.reducer,
  [DeleteHcPhoto.reducerPath]: DeleteHcPhoto.reducer,
  [AuthApi.reducerPath]: AuthApi.reducer,
  [RevertOfferAcceptanceApi.reducerPath]: AuthApi.reducer,
  [ManageInternshipsApi.reducerPath]: ManageInternshipsApi.reducer,
  [RevertCandidatureConfirmationApi.reducerPath]: RevertCandidatureConfirmationApi.reducer,
  [ManageVideoApplicationApi.reducerPath]: ManageVideoApplicationApi.reducer,
  [PostCustomMailApi.reducerPath]: PostCustomMailApi.reducer,
  [GetApplicationPAMApi.reducerPath]: GetApplicationPAMApi.reducer,
  [GetBatchCodeListApi.reducerPath]: GetBatchCodeListApi.reducer,
  [GetInternProfileDetailsApi.reducerPath]: GetInternProfileDetailsApi.reducer,
  [ManagePlacementsApi.reducerPath]: ManagePlacementsApi.reducer,
  [BlacklistCompanyApi.reducerPath]: BlacklistCompanyApi.reducer,
  [GetHcDetailsByIdOrEmailApi.reducerPath]: GetHcDetailsByIdOrEmailApi.reducer,
  [HostCompanyTableApi.reducerPath]: HostCompanyTableApi.reducer,
  [GetInterviewDetailsApi.reducerPath]: GetInterviewDetailsApi.reducer,
  [GetAnalyticsDetailsApi.reducerPath]: GetAnalyticsDetailsApi.reducer,
  [GetInternDetailsByEmailApi.reducerPath]: GetInternDetailsByEmailApi.reducer,
  [ManageRecommendationsApi.reducerPath]: ManageRecommendationsApi.reducer,
  [GetOfferListApi.reducerPath]: GetOfferListApi.reducer,
  [AssignAEApi.reducerPath]: AssignAEApi.reducer,
  [AssignCamApi.reducerPath]: AssignCamApi.reducer,
  [CreateCompanyApi.reducerPath]: CreateCompanyApi.reducer,
  [GetCountByStartDateApi.reducerPath]: GetCountByStartDateApi.reducer,
  [MarkCompanySignupComplete.reducerPath]: MarkCompanySignupComplete.reducer,
  [ManagePartnerList.reducerPath]: ManagePartnerList.reducer,
  [ManageNotesApi.reducerPath]: ManageNotesApi.reducer,
  [GetProfileApi.reducerPath]: GetProfileApi.reducer,
  [ManageBatchApi.reducerPath]: ManageBatchApi.reducer,
  [InterviewApi.reducerPath]: InterviewApi.reducer,
  Snackbar: snackbarReducer,
};
