import { configs } from './configs';
import moment from 'moment-timezone';
import { NavLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import _reduce from 'lodash/reduce';
import _isEmpty from 'lodash/isEmpty';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckIcon from '@mui/icons-material/Check';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Chip, Stack } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { showSnackbar } from 'Redux/Slices/Common/SnackbarSlice';

import TruncatedTextWithTooltip from 'Components/Common/Mui/TruncatedTextWithTooltip';
import { datadogLogs } from '@datadog/browser-logs';

const EXT_FE_BASE_URL = configs.EXT_FE_BASE_URL;

export const getUrlParams = (urlParameter) => {
  let searchOptions = { where: {} };
  let sortOptions = { sort: {} };
  let paginationOptions = { pagination: {} };
  let filterOptions = { filter: {} };
  let type = '';
  let mode = '';

  urlParameter &&
    Object.keys(urlParameter)?.map((key) => {
      if (key.includes('filter')) {
        let newKey = key.replace('filter_', '');
        filterOptions.filter[newKey] = urlParameter[key];
      } else if (key.includes('search')) {
        let newKey = key.replace('search_', '');
        searchOptions.where[newKey] = { _like: urlParameter[key] };
      } else if (key.includes('sort')) {
        let newKey = key.replace('sort_', '');
        sortOptions.sort[newKey] = urlParameter[key];
      } else if (key.includes('pagination')) {
        let newKey = key.replace('pagination_', '');
        paginationOptions.pagination[newKey] = parseInt(urlParameter[key]);
      } else if (key.includes('type')) {
        type = urlParameter[key];
      } else if (key.includes('mode')) {
        mode = urlParameter[key];
      }
    });

  return {
    searchOptions,
    sortOptions,
    paginationOptions,
    filterOptions,
    type,
    mode,
  };
};

export const removeParams = (urlParameter, paramToRemove = []) => {
  let params = {};
  urlParameter &&
    Object.keys(urlParameter).map((key) => {
      if (paramToRemove.some((param) => key.includes(param))) {
        params[key] = undefined;
      }
    });

  return params;
};

export const AddFilterPrefix = (object = {}) => {
  let tempObj = {};
  Object.keys(object).map((key) => {
    tempObj = {
      ...tempObj,
      [`filter_${key}`]: object[key],
    };
  });
  return tempObj;
};

export const titleCase = (string) => {
  let result = string.toLowerCase().split(' ');
  for (let i = 0; i < result.length; i++) {
    result[i] = result[i][0].toUpperCase() + result[i].slice(1);
  }
  return result;
};

export const getNameInitials = (name) => {
  if (name) {
    const fullName = name.split(' ');
    const initials = fullName.shift()?.charAt(0) || '' + fullName.pop()?.charAt(0) || '';
    return initials.toUpperCase();
  } else {
    return name;
  }
};

export const getSelectedOptions = (id, list) => {
  return list?.filter((obj) => {
    if (!isNaN(parseInt(id))) return parseInt(id) === obj?.value || id === obj?.value;
    else return obj?.value === id;
  });
};

export const getSelectedOptionsWithValueZero = (id, list) => {
  return list?.filter((obj) => {
    return parseInt(id) === obj?.value;
  });
};

export const getMultiSelectOptions = (selectedIdsList = [], list) => {
  if (Array.isArray(selectedIdsList)) {
    return selectedIdsList?.map((item) => {
      return getSelectedOptions(item, list)?.[0];
    });
  } else {
    datadogLogs.logger.log('Select options error', { selectedIdsList, list }, 'error', null);
    return [];
  }
};

export const getMultiSelectOptionsWithZero = (selectedIdsList = [], list) => {
  return selectedIdsList?.map((item) => {
    return getSelectedOptionsWithValueZero(item, list)?.[0];
  });
};

export const getDateRangeSelectedValue = (startdate, enddate) => {
  if (startdate && enddate) {
    return [moment(startdate), moment(enddate)];
  } else return null;
};

export const formatFloatingNumbers = (num, regex, delim) => {
  const values = num.toString().split('.');
  values[0] = values[0].replace(regex, delim);
  return values.join('.');
};

// Converts to array of objects of id and value
export const arrToArrayOfObjects = (arr) => {
  return arr.reduce((prev, curr, i) => {
    let ob = {};
    ob['id'] = i;
    ob['value'] = curr;
    prev.push(ob);
    return prev;
  }, []);
};

export const slugify = (batchSlug = '', partnerSlug) => {
  batchSlug = batchSlug
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '');
  return partnerSlug
    ? `${EXT_FE_BASE_URL}/${partnerSlug}/${batchSlug}`
    : `${EXT_FE_BASE_URL}/${batchSlug}`;
};

export const formatDateInTimezone = (date, zoneName, formatArg) => {
  const format = formatArg?.replace(/D/g, 'd')?.replace(/Y/g, 'y')?.replace(/A/g, 'a');
  const luxonDate = convertToTimeZoneAndFormat(
    new Date(date),
    zoneName || 'ETC/UTC',
    format || 'yyyy-MM-dd HH:mm:ss',
  );
  return luxonDate;
  // return moment(date)
  //   ?.tz(zoneName || 'ETC/UTC')
  //   ?.format(format || 'YYYY-MM-DD HH:mm:ss');
};

export const getDateAsNumber = (date, zoneName) => {
  return moment(date).tz(zoneName).format('X');
};

export const getUniqueArrOfObjects = (arr, listOfKeysToCheck) => {
  return arr.filter(
    (v, i, a) => a.findIndex((v2) => listOfKeysToCheck.every((k) => v2[k] === v[k])) === i,
  );
};

export const onKeyEnter = (e, callback) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    callback();
  }
};

export const checkDimensionsOfImage = async (file) => {
  let reader = new FileReader();
  // Read the contents of Image File.
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };
    reader.onload = function (e) {
      // Initiate the JavaScript Image object.
      let image = new Image();

      // Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      // Validate the File Height and Width.
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        resolve({ height: height, width: width });
      };
    };
  });
};

export const paginate = (dataSet = [], page, rows) => {
  let trimStart = (page - 1) * rows;
  let trimEnd = trimStart + rows;
  let trimData = dataSet?.slice(trimStart, trimEnd);
  let pages = Math.ceil(dataSet?.length / rows);

  return { dataSet: trimData, pages: pages };
};

export const getYoutubeId = (url) => {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
};

export const getVimeoId = (url) => {
  const match = /vimeo.*\/(\d+)/i.exec(url);
  if (match) {
    return match[1];
  }
};

export const embedLink = (url) => {
  if (url?.includes('vimeo.com')) {
    return `https://player.vimeo.com/video/${getVimeoId(url)}`;
  } else if (url?.includes('youtube.com') || url?.includes('youtu.be')) {
    if (url?.includes('shorts')) {
      return url.replace('shorts', 'embed');
    }
    return `https://www.youtube.com/embed/${getYoutubeId(url)}`;
  }
  return getCdnUrl('/Images/Global/No-thumbnail-grey.svg');
};

export const checkParamValue = (param) => {
  if (param !== undefined && param !== null && param !== '') {
    return true;
  } else {
    return false;
  }
};

export const checkArrayIsValid = (arr) => {
  if (Array.isArray(arr) && arr.length > 0) {
    return true;
  }
  return false;
};

export const isEmptyObject = (obj) => {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) return false;
  }
  return true;
};

export const allValuesNullOrUndefinedOrEmpty = (obj) => {
  for (let key in obj) {
    if (obj[key] || obj[key] === 0) {
      return false;
    }
  }
  return true;
};
export const getVideoApplicationStatus = (status) => {
  switch (status) {
    case 'Video application submitted':
    case 'Video application submitted (Revised)':
      return 'Review pending';
    case 'Approved':
      return 'Approved';
    case 'Feedback Provided':
      return 'Awaiting response';
    case 'Rejected':
      return 'Rejected';
    case 'Withdrawn':
      return 'Withdrawn';
    default:
      return null;
  }
};
export const applicationStatus = (status) => {
  switch (status) {
    case 'Not started':
      return 'Not started';
    case 'Application in progress':
      return 'In progress';
    case 'Application submitted':
      return 'Submitted (Original)';
    case 'Application submitted (Revised)':
      return 'Submitted (Revised)';
    case 'Approved':
      return 'Approved';
    case 'Rejected':
      return 'Rejected';
    case 'Withdrawn':
      return 'Withdrawn';
    case 'Expired':
      return 'Expired';
    case 'Enrolment complete':
      return 'Enrolled';
    default:
      return null;
  }
};
export function convertToMMMYY(dateString) {
  if (dateString === null) {
    return '';
  }
  const [month, year] = dateString.split('/');
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const monthNum = parseInt(month, 10);
  const formattedMonth = monthNames[monthNum - 1];
  const formattedYear = year;
  return `${formattedMonth} ${formattedYear}`;
}
export const checkEnglishProficiency = (state) => {
  if (state === 'Fluent' || state === 'Native') {
    return true;
  }
  return false;
};
export const getUserFullName = (firstName = '', lastName) => {
  return firstName + (lastName ? ' ' + lastName : '');
};

export function isVideoAnswerURLPresent(array) {
  let result;
  // If the array is empty, return false
  if (array?.length === 0) {
    result = false;
  }

  // Check each item in the array for the presence of video_answer_url

  for (const item of array) {
    if (!item.video_answer_url || item.video_answer_url.trim() === '') {
      // If video_answer_url is missing or empty in any item, return false
      // result = false;
      console.log('marking result false');
    } else {
      // If video_answer_url is present and not empty in all items, return true
      result = true;
    }
  }

  return result;
}

export const seconds2time = (secondsDataVal) => {
  const secondsData = Math.round(secondsDataVal);
  const hours = Math.floor(secondsData / 3600);
  let minutes = Math.floor((secondsData - hours * 3600) / 60);
  const seconds = secondsData - hours * 3600 - minutes * 60;
  let time = '';

  if (hours !== 0) {
    time = `${hours}:`;
  }
  if (minutes !== 0 || time !== '') {
    minutes = minutes < 10 && time !== '' ? `0${minutes}` : String(minutes);
    time += `${minutes}:`;
  }
  if (time === '') {
    time += seconds < 10 ? `00:0${seconds}` : `00:${String(seconds)}`;
  } else {
    time += seconds < 10 ? `0${seconds}` : String(seconds);
  }

  return time;
};
const Utils = {
  isFunction(value) {
    return typeof value === 'function';
  },

  isUndefined(value) {
    return typeof value === 'undefined';
  },

  isNull(value) {
    return value === 'null';
  },
  isEmpty(value) {
    return value === '';
  },
  isBoolean(value) {
    return typeof value === 'boolean';
  },

  isNumber(value) {
    return typeof value === 'number';
  },

  isEmptyList(value) {
    return value.constructor !== Array || value.length === 0;
  },

  isEmptyObject(value) {
    if (value === null) {
      return true;
    }

    if (Utils.isBoolean(value) || Utils.isNumber(value) || Utils.isFunction(value)) {
      return false;
    }

    if (value instanceof Set && !Utils.isUndefined(value.size) && value.size !== 0) {
      return false;
    }

    return Object.keys(value).length === 0;
  },

  isStringAndNotEmpty(value) {
    return typeof value === 'string' && value !== '';
  },

  isString(value) {
    return typeof value === 'string';
  },

  isUndefinedOrNull(value) {
    return Utils.isUndefined(value) || Utils.isNull(value);
  },

  isUndefinedOrNullOrEmpty(value) {
    if (value === null) {
      return true;
    }
    return Utils.isUndefinedOrNull(value) || Utils.isEmpty(value);
  },

  isUndefinedOrNullOrEmptyObject(value) {
    return Utils.isUndefinedOrNullOrEmpty(value) || Utils.isEmptyObject(value);
  },

  isUndefinedOrNullOrEmptyList(value) {
    return Utils.isUndefinedOrNull(value) || Utils.isEmptyList(value);
  },

  isUndefinedOrNullOrEmptyOrEmptyObjectOrEmptyList(value) {
    return (
      Utils.isUndefinedOrNullOrEmpty(value) ||
      Utils.isEmptyObject(value) ||
      Utils.isUndefinedOrNullOrEmptyList(value)
    );
  },

  getNextMonthsandYear(count) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const today = new Date();
    let d;
    let month;
    let year;
    const result = [];
    for (let i = 0; i < count; i += 1) {
      d = new Date(today.getFullYear(), today.getMonth() + i + 1, 1);
      month = monthNames[d.getMonth()];
      year = d.getFullYear();
      const val = `${month?.slice(0, 3)} ${year}`;
      result.push({ label: val, value: val });
    }
    return result;
  },
};
export const reverseMMYYYY = (date) => {
  if (Utils.isUndefinedOrNull(date) || date === null) {
    return null;
  }
  const newString = date?.split('/')?.[1] + '/' + date?.split('/')?.[0];
  return moment(newString);
};
export function convertToTimeZoneAndFormat(
  date,
  timezone = 'UTC',
  dateFormat = '',
  isBefore = false,
  uniqueIdentifier,
) {
  try {
    let luxonDate;
    if (uniqueIdentifier) {
      console.log(uniqueIdentifier, date, timezone, isBefore);
    }

    // Detect the input format and create a Luxon DateTime object accordingly
    if (date instanceof Date) {
      // JavaScript Date object
      luxonDate = DateTime.fromJSDate(date);
    } else if (typeof date === 'string' && date.match(/^\d{4}-\d{2}-\d{2}/)) {
      // ISO 8601 date-time string
      luxonDate = DateTime.fromISO(date);
    } else if (typeof date === 'number' && date.toString().length === 10) {
      // Unix timestamp in seconds
      luxonDate = DateTime.fromMillis(date * 1000);
    } else if (typeof date === 'number' && date.toString().length === 13) {
      // Unix timestamp in milliseconds
      luxonDate = DateTime.fromMillis(date);
    } else {
      throw new Error('Invalid date format');
    }

    if (timezone !== 'UTC') {
      // Set the timezone if it's not UTC
      luxonDate = luxonDate.setZone(timezone);
    }

    if (isBefore) {
      // If isBefore is true, perform a date comparison
      const now = DateTime.now().setZone(timezone);
      return luxonDate < now;
    } else {
      // If isBefore is false or not provided, conditionally format the DateTime object

      return dateFormat ? luxonDate.toFormat(dateFormat) : luxonDate;
    }
  } catch (error) {
    // Handle any errors, e.g., invalid date format
    console.error('Error:', error.message);
    return null; // Return null or an error message as needed
  }
}
export function isUnder18Years(dateOfBirth) {
  // Convert date strings to Date objects
  const dob = new Date(dateOfBirth);
  const now = new Date();

  // Calculate the age in years
  let age = now.getFullYear() - dob.getFullYear();

  // Check if the birthday for this year has occurred or not
  const hasBirthdayOccurred =
    now.getMonth() > dob.getMonth() ||
    (now.getMonth() === dob.getMonth() && now.getDate() >= dob.getDate());

  // Adjust the age based on the birthday
  if (!hasBirthdayOccurred) {
    age--;
  }

  // Check if the age is less than 18 years
  if (age < 18) {
    return true;
  } else {
    return false;
  }
}

export const objectToQueryString = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return '';
  }
  return Object.entries(obj)
    .filter(([key, value]) => {
      if (Array.isArray(value) && key) {
        return value.length > 0;
      }
      return value !== undefined && value !== null;
    })
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${encodeURIComponent(key)}[]=${value
          .map((item) => encodeURIComponent(item))
          .join(',')}`;
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');
};

export const getParamObjectModifier = (request) => {
  let modifiedParam = {};
  if (request?.filter) {
    Object.keys(request?.filter)?.forEach((item) => {
      modifiedParam[item] = request?.filter?.[item];
    });
  }
  if (request?.pagination) {
    if (request?.pagination?.pageNumber)
      modifiedParam['page_number'] = request?.pagination?.pageNumber;

    if (request?.pagination?.perPage) modifiedParam['per_page'] = request?.pagination?.perPage;
  }
  if (request?.search_text) {
    modifiedParam['search_text'] = request?.search_text;
  }

  if (request?.sort?.column) {
    modifiedParam['sort_column'] = request?.sort?.column;
  }
  if (request?.sort?.order) {
    modifiedParam['sort_order'] = request?.sort?.order;
  }
  if (request?.filter?.active_interview) {
    modifiedParam['has_active_interview'] = request?.filter?.active_interview;
    delete modifiedParam.active_interview;
  }
  if (request?.application_id) {
    modifiedParam['application_id'] = request?.application_id;
  }

  if (request?.partner_ids) {
    modifiedParam['partner_ids'] = request?.partner_ids;
  }

  return modifiedParam;
};

export default Utils;

export function formatUnixTimestamp(timestamp) {
  try {
    // Ensure the input is a valid timestamp (Unix epoch is January 1, 1970)
    if (isNaN(timestamp) || timestamp < 0 || timestamp > 2147483647) {
      throw new Error('Invalid timestamp');
    }

    // Convert the timestamp to a Luxon DateTime object
    const dateTime = DateTime.fromSeconds(timestamp);

    // Get the day of the month
    const day = dateTime.day;

    // Add the appropriate suffix to the day
    let dayWithSuffix;
    if (day === 1 || day === 21 || day === 31) {
      dayWithSuffix = `${day}st`;
    } else if (day === 2 || day === 22) {
      dayWithSuffix = `${day}nd`;
    } else if (day === 3 || day === 23) {
      dayWithSuffix = `${day}rd`;
    } else {
      dayWithSuffix = `${day}th`;
    }

    // Format the DateTime object as "dS MMMM, yyyy"
    const formattedDate = dateTime.toFormat(`'${dayWithSuffix}' MMM, yyyy`);

    return formattedDate;
  } catch (error) {
    return `Error: ${error.message}`;
  }
}

export const calculateOfferEndDate = (startDate, duration) => {
  const endDate = Math.floor(startDate) + duration * 604800;
  const endDateObject = new Date(endDate * 1000);
  if (endDateObject.getDay() === 1) {
    endDateObject.setDate(endDateObject.getDate() - 3);
  }
  const modifiedDate = Math.floor(endDateObject.getTime() / 1000);
  const dateTime = DateTime.fromSeconds(modifiedDate);
  // Get the day of the month
  const day = dateTime?.day;
  let dayWithSuffix;
  if (day === 1 || day === 21 || day === 31) {
    dayWithSuffix = `${day}st`;
  } else if (day === 2 || day === 22) {
    dayWithSuffix = `${day}nd`;
  } else if (day === 3 || day === 23) {
    dayWithSuffix = `${day}rd`;
  } else {
    dayWithSuffix = `${day}th`;
  }

  const formattedDate = dateTime?.toFormat(`'${dayWithSuffix}' MMMM, yyyy`);
  return formattedDate;
};

export function addWeeksAndFormat(timestamp, weeksToAdd) {
  try {
    // Ensure the input is a valid timestamp (Unix epoch is January 1, 1970)
    if (isNaN(timestamp) || timestamp < 0 || timestamp > 2147483647) {
      throw new Error('Invalid timestamp');
    }

    // Ensure the weeksToAdd is a valid number
    if (isNaN(weeksToAdd)) {
      throw new Error('Invalid number of weeks');
    }

    // Convert the timestamp to a Luxon DateTime object
    const dateTime = DateTime.fromSeconds(timestamp);

    // Add the specified number of weeks
    const newDateTime = dateTime.plus({ weeks: weeksToAdd });

    // Get the day of the month
    const day = newDateTime.day;

    // Add the appropriate suffix to the day
    let dayWithSuffix;
    if (day === 1 || day === 21 || day === 31) {
      dayWithSuffix = `${day}st`;
    } else if (day === 2 || day === 22) {
      dayWithSuffix = `${day}nd`;
    } else if (day === 3 || day === 23) {
      dayWithSuffix = `${day}rd`;
    } else {
      dayWithSuffix = `${day}th`;
    }

    // Format the new DateTime object as "dS MMMM, yyyy"
    const formattedDate = newDateTime.toFormat(`'${dayWithSuffix}' MMMM, yyyy`);

    return formattedDate;
  } catch (error) {
    return '';
  }
}

export const getCdnUrl = (url) => {
  return configs.REACT_APP_ASSET_BASE_URL + url;
};

export const filterOption = (input, options) =>
  options?.label?.toString()?.toLowerCase()?.includes(input.toLowerCase()) ||
  options?.value?.toString()?.toLowerCase()?.includes(input.toLowerCase());

export const getVideoStatusLabel = (status) => {
  let content;
  switch (status) {
    case 'Approved':
      content = {
        color: 'success',
        icon: <CheckCircleOutlineIcon sx={{ fontSize: 8 }} />,
      };
      break;
    case 'Review pending':
    case 'Review pending(Resubmitted)':
      content = {
        color: 'warning',
        icon: <InfoOutlinedIcon sx={{ fontSize: 8 }} />,
      };
      break;
    case 'Rejected':
      content = {
        color: 'error',
        icon: <HighlightOffIcon sx={{ fontSize: 8 }} />,
      };
      break;
    default:
      content = {
        color: 'warning',
        icon: <InfoOutlinedIcon sx={{ fontSize: 8 }} />,
      };
  }

  return (
    <Stack direction='row' gap={1}>
      <Chip icon={content.icon} color={content.color} label={status} variant='outlined' />
    </Stack>
  );
};

export const getVideoStatusSecondaryLabel = (status) => {
  let content;
  switch (status) {
    case 'Feedback in draft':
      content = {
        icon: <AddCircleOutlineIcon sx={{ fontSize: 8 }} />,
        text: 'Feedback in draft',
      };
      break;
    case 'Feedback shared':
      content = {
        icon: <CheckIcon sx={{ fontSize: 8 }} />,
        text: 'Feedback shared',
      };
      break;
  }

  return content ? (
    <Stack direction='row' gap={1}>
      <Chip icon={content.icon} label={content.text} variant='outlined' />
    </Stack>
  ) : (
    <></>
  );
};

export const capitalizeFirstLetter = (str) => {
  return str?.[0]?.toUpperCase() + str?.slice(1);
};

export const checkDuplicateInArr = (value, orgArr) => {
  if (typeof value === 'string') {
    return orgArr.some((tag) => tag === value);
  } else if (Array.isArray(value)) {
    const combinedArray = [...orgArr, ...value];
    const uniqueValues = new Set(combinedArray);
    return combinedArray.length !== uniqueValues.size;
  }
};

export const removeDuplicatesInArr = (arr) => {
  return [...new Set(arr)];
};

export const extractEmail = (inputString) => {
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

  const emailMatch = inputString.match(emailRegex);

  return emailMatch ? emailMatch[0] : null;
};

export const exportToCSV = (dataToExport, fileName) => {
  const fileType = 'xlsx';
  dataToExport.map((item) => {
    item['json'] = XLSX.utils.json_to_sheet(item.data);
  });
  const obj = { Sheets: {}, SheetNames: [] };
  dataToExport.map((item) => {
    (obj.Sheets[item.category] = item.json), obj.SheetNames.push(item.category);
  });
  const test = { ...obj };
  const excelBuffer = XLSX.write(test, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, fileName + new Date() + '.xlsx');
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  const nameParts = name.split(' ');
  const initials =
    nameParts.length > 1 && nameParts[1].length > 1
      ? `${nameParts[0][0]}${nameParts[1][0]}`
      : `${nameParts[0][0]}`;

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
}

export const addCustomHyperlink = ({
  url,
  text,
  showIcon = false,
  maxChars = 30,
  width,
  children,
  textStyleClass,
  openInNewTab = true,
}) => {
  return (
    <NavLink
      to={url}
      target={openInNewTab ? '_blank' : null}
      className={`user-group-name width-max-content ${
        textStyleClass ? textStyleClass : 'text-sf-semi-regular link-text'
      }`}
      rel='noreferrer'
    >
      <div className='' style={{ width }}>
        {text && <TruncatedTextWithTooltip text={text} maxChars={maxChars} />}
        {showIcon ? <OpenInNewIcon fontSize='small' /> : null}
        {children}
      </div>
    </NavLink>
  );
};

export const selectAllOnChange = (ids, filterKey, options, updateUrlParameter) => {
  if (ids.includes('select_all')) {
    updateUrlParameter({
      [`${filterKey}`]: options?.map((option) => option.value),
    });
  } else if (ids.includes('deselect_all')) {
    updateUrlParameter({
      [`${filterKey}`]: undefined,
    });
  } else {
    updateUrlParameter({
      [`${filterKey}`]: ids,
    });
  }
};

export const ceilNearest100 = (value) => {
  return Math.ceil(value / 100) * 100;
};

export const removeValueFromUrl = (valueToRemove, navigate) => {
  const searchParams = new URLSearchParams(location.search);
  if (searchParams.has(valueToRemove)) {
    searchParams.delete(valueToRemove);
    const newSearch = searchParams.toString();
    navigate({ search: newSearch });
  }
};

export const copyEmail = (email, dispatch, toastMessage = 'Email copied') => {
  dispatch(
    showSnackbar({
      severity: 'success',
      alertTitle: toastMessage,
    }),
  );
  navigator?.clipboard?.writeText(email);
};

export const convertTimestampToDate = (timestamp) => {
  const dateTime = DateTime.fromMillis(timestamp * 1000);

  const formattedDate = dateTime.toFormat('dd LLL yyyy');
  return formattedDate;
};

export const getLeftCharactersTextColor = (charactersLeft) => {
  return charactersLeft >= 100 ? 'color-69707D' : 'color-FAAF00';
};

export const htmlToPlainTextLength = (html) => {
  const textWithoutTags = html ? html.replace(/<[^>]*>/g, '').replace(/\n/g, '') : '';
  const textLength = textWithoutTags.length;
  return textLength;
};

export const parseInUnix = (date) => {
  const parsedMoment = moment(date).tz('Etc/UTC');
  parsedMoment.set({ hour: 10, minute: 0, second: 0, millisecond: 0 });
  const timestamp = parsedMoment.unix();
  return timestamp;
};

export const weekDuration = Array.from({ length: 20 }, (_, index) => ({
  label: index + 1,
  value: index + 1,
}));
export const getFileTypeFromURL = (url) => {
  const segments = url?.split('.');
  const extension = segments?.pop();
  return extension?.toLowerCase(); // Convert to lowercase for consistency
};

export const getCamListFilterOptions = (camApiResponse) =>
  camApiResponse?.data?.payload?.employees?.map((cse) => ({
    label: cse?.full_name,
    value: cse?.employee_id,
  }));

export const isUnapprovedCompanySelected = (selectedCompanyIds, blackListedCompanyIdsList) => {
  let isBlackListCompanySelected = false;
  selectedCompanyIds.forEach((companyId) => {
    if (blackListedCompanyIdsList.includes(companyId)) {
      isBlackListCompanySelected = true;
      return isBlackListCompanySelected;
    }
  });
  return isBlackListCompanySelected;
};

export const selectOnChange = (ids, props, updateUrlParameter) => {
  if (props?.filterKey) {
    if (props.mode !== 'single' && ids.includes('select_all')) {
      updateUrlParameter({
        [`${props.filterKey}`]: props?.options?.map((option) => option.value),
      });
    } else if (props.mode !== 'single' && ids.includes('deselect_all')) {
      updateUrlParameter({
        [`${props.filterKey}`]: undefined,
      });
    } else {
      updateUrlParameter({
        [`${props.filterKey}`]: ids,
      });
    }
  } else {
    props.onChange(ids);
  }
};

export const selectOptions = (props, filterSelectProps, urlParameter) => {
  if (props?.mode === 'single') {
    return props.options;
  }
  if (filterSelectProps.mode === 'multiple' && props.filterKey) {
    return [
      {
        label: 'Select All',
        value: 'select_all',
        disabled: urlParameter[`${props.filterKey}`]?.length === props?.options?.length,
      },
      {
        label: 'Deselect All',
        value: 'deselect_all',
        disabled: !urlParameter[`${props.filterKey}`],
      },
      ...props.options,
    ];
  }
  return props.options;
};

export const getAeListFilterOptions = (aeListApiResponse) =>
  aeListApiResponse?.payload?.map((ae) => ({
    label: ae?.full_name,
    value: Number(ae?.employee_id),
  }));

export const showSuccessMsg = (message, dispatch) => {
  dispatch(
    showSnackbar({
      severity: 'success',
      alertTitle: 'Success',
      message,
    }),
  );
};

export const showErrorMsg = (message, dispatch) => {
  dispatch(
    showSnackbar({
      severity: 'error',
      alertTitle: 'Error',
      message,
    }),
  );
};

export const filterNonEmptyArrays = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => Array.isArray(value) && value.length > 0),
  );
};

export const hasAnyValue = (obj) => {
  return Object.values(obj).some((array) => Array.isArray(array) && array.length > 0);
};

export const countEmptyValues = (obj) => {
  return _reduce(
    obj,
    (count, value) => {
      if (_isEmpty(value)) {
        return count + 1;
      }
      return count;
    },
    0,
  );
};

export function daysRemainingUntil(dateString, isPastDate = false) {
  const targetDate = new Date(dateString);
  const currentDate = new Date();
  const timeDifference = isPastDate
    ? currentDate.getTime() - targetDate.getTime()
    : targetDate.getTime() - currentDate.getTime();
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return `${daysRemaining} Day${daysRemaining > 1 ? 's' : ''}`;
}
