import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosInstance';

import { objectToQueryString } from '../../../Util/utility';
import { onQueryStartedErrorToast } from 'Util/rtkQueryErrorHandler';

const url = '/api/v1/internal-service/dashboard/';
const AE_ALLOCATED_HC_URL = '/api/v1/internal-service/dashboard/ae-allocated-hcs';

export const GetAnalyticsDetailsApi = createApi({
  reducerPath: 'GetAnalyticsDetailsApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getAnalyticsDetails: builder.query({
      query: (request) => {
        const queryString = objectToQueryString(request);
        const updatedUrl = queryString ? `${url}?${queryString}` : url;
        return { url: updatedUrl, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
    }),
    getAeAllocatedHcAnalytics: builder.query({
      query: (request) => {
        const queryString = objectToQueryString(request);
        const updatedUrl = queryString
          ? `${AE_ALLOCATED_HC_URL}?${queryString}`
          : AE_ALLOCATED_HC_URL;
        return { url: updatedUrl, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
    }),
  }),
});

export const { useGetAnalyticsDetailsQuery, useGetAeAllocatedHcAnalyticsQuery } =
  GetAnalyticsDetailsApi;

export default GetAnalyticsDetailsApi.reducer;
