import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';
import { getParamObjectModifier, objectToQueryString } from '../../../Util/utility';
import { onQueryStartedErrorToast } from 'Util/rtkQueryErrorHandler';

const getInternListURL = '/api/v1/internal-service/pam/application';
const confirmEnrolmentReminderUrl = '/api/v1/internal-service/intern/confirm-enrolment-reminder';
const bulkUpdateUrl = '/api/v1/internal-service/pam/application/verify-emails';
const activeOfferUrl = '/api/v1/internal-service/pam/application/list-active-offers';

export const GetApplicationPAMApi = createApi({
  reducerPath: 'GetApplicationPAMApi',
  tagTypes: ['get-application', 'get-application-by-id'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    // With filters
    getApplicationPAM: builder.query({
      query: (data) => {
        const modifiedParam = getParamObjectModifier(data);
        return { url: getInternListURL, method: 'GET', body: modifiedParam };
      },
      providesTags: ['get-application'],
    }),
    getApplicationPAMById: builder.query({
      query: (data) => {
        return { url: `${getInternListURL}/${data.application_id}`, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
      providesTags: ['get-application-by-id'],
    }),
    revertApplicationToSubmitted: builder.mutation({
      query: (applicationId) => {
        return {
          url: `${getInternListURL}/${applicationId}/revert-to-submit`,
          method: 'PATCH',
        };
      },
      onQueryStarted: onQueryStartedErrorToast,
      invalidatesTags: (result, error) =>
        error ? [] : ['get-application-by-id', 'get-application'],
    }),
    confirmEnrolmentReminder: builder.mutation({
      query: (body) => {
        return {
          url: confirmEnrolmentReminderUrl,
          method: 'POST',
          body,
        };
      },
    }),
    getCareerFieldProjects: builder.query({
      query: (data) => {
        return {
          url: `/api/v1/internal-service/pam/application/${data.application_id}/career-field/projects`,
          method: 'GET',
        };
      },
    }),
    bulkUpdateCheck: builder.mutation({
      query: (data) => {
        return { url: bulkUpdateUrl, method: 'POST', body: data };
      },
    }),

    updateProfileValues: builder.mutation({
      query: (request) => {
        let internId = request.intern_id;
        let type = request.type;
        delete request['intern_id'];
        delete request['type'];
        const url = '/api/v1/internal-service/intern/' + internId + '/' + type;
        return { url, method: 'PATCH', body: request };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    updateDetails: builder.mutation({
      query: (request) => {
        let applicationId = request.application_id;
        delete request['application_id'];
        const url = '/api/v1/internal-service/pam/application/' + applicationId;
        return { url, method: 'PATCH', body: request };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    updateAdditionalQuestions: builder.mutation({
      query: (data) => {
        const applicationId = data?.application_id;
        const customId = data?.custom_form_answer_id;
        delete data.application_id;
        delete data.custom_form_answer_id;

        return {
          url: `api/v1/internal-service/pam/application/${applicationId}/update-answer/${customId}`,
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    createAdditionalQuestionsData: builder.mutation({
      query: (data) => {
        const applicationId = data?.application_id;
        const customId = data?.custom_form_id;
        delete data.application_id;
        delete data.custom_form_id;

        return {
          url: `api/v1/internal-service/pam/application/${applicationId}/update-answer/${customId}`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    updateCertification: builder.mutation({
      query: (request) => {
        let internId = request.intern_id;
        let id = request.id;
        delete request['intern_id'];
        delete request['id'];
        const url = `/api/v1/internal-service/intern/${internId}/certification/${id}`;
        return { url, method: 'PUT', body: request };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    createCertification: builder.mutation({
      query: (request) => {
        let internId = request.intern_id;
        delete request['intern_id'];
        const url = `/api/v1/internal-service/intern/${internId}/certification`;
        return { url, method: 'POST', body: request };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    createEducation: builder.mutation({
      query: (request) => {
        let internId = request.intern_id;
        delete request['intern_id'];
        const url = `/api/v1/internal-service/intern/${internId}/education`;
        return { url, method: 'POST', body: request };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    updateEducation: builder.mutation({
      query: (request) => {
        let internId = request.intern_id;
        let id = request.id;
        delete request['intern_id'];
        delete request['id'];
        const url = `/api/v1/internal-service/intern/${internId}/education/${id}`;
        return { url, method: 'PUT', body: request };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    deleteEducation: builder.mutation({
      query: (request) => {
        const url = `/api/v1/internal-service/intern/${request.intern_id}/education/${request.id}`;
        return { url, method: 'DELETE' };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    deleteCertification: builder.mutation({
      query: (request) => {
        const url = `/api/v1/internal-service/intern/${request.intern_id}/certification/${request.id}`;
        return { url, method: 'DELETE' };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    updateWorkEx: builder.mutation({
      query: (request) => {
        let internId = request.intern_id;
        delete request['intern_id'];
        const url = `/api/v1/internal-service/intern/${internId}/save-work-experience`;
        return { url, method: 'PUT', body: request };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    updateVolunteer: builder.mutation({
      query: (request) => {
        let internId = request.intern_id;
        delete request['intern_id'];
        const url = `/api/v1/internal-service/intern/${internId}/save-volunteer`;
        return { url, method: 'PUT', body: request };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    deleteWorkEx: builder.mutation({
      query: (request) => {
        const url = `/api/v1/internal-service/intern/${request.intern_id}/work-experience/${request.id}`;
        return { url, method: 'DELETE' };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    deleteVolunteer: builder.mutation({
      query: (request) => {
        const url = `/api/v1/internal-service/intern/${request.intern_id}/volunteer/${request.id}`;
        return { url, method: 'DELETE' };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    updatePortfolio: builder.mutation({
      query: (request) => {
        let internId = request.intern_id;
        let id = request.id;
        delete request['intern_id'];
        delete request['id'];
        const url = `/api/v1/internal-service/intern/${internId}/portfolio/${id}`;
        return { url, method: 'PUT', body: request };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    createPortfolio: builder.mutation({
      query: (request) => {
        let internId = request.intern_id;
        delete request['intern_id'];
        const url = `/api/v1/internal-service/intern/${internId}/portfolio`;
        return { url, method: 'POST', body: request };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    deletePortfolio: builder.mutation({
      query: (request) => ({
        url: `/api/v1/internal-service/intern/${request?.intern_id}/portfolio/${request?.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['get-application-by-id'],
    }),
    updateInternDob: builder.mutation({
      query: (request) => {
        let internId = request.intern_id;
        delete request['intern_id'];
        delete request['type'];
        return {
          url: `/api/v1/internal-service/intern/${internId}/dob`,
          method: 'PATCH',
          body: request,
        };
      },
      invalidatesTags: (result, error) => (error ? [] : ['get-application-by-id']),
    }),
    approvePAM: builder.mutation({
      query: (body) => {
        return { url: '/api/v1/internal-service/pam/application/approve', method: 'PATCH', body };
      },
      invalidatesTags: (result, error) =>
        error ? [] : ['get-application', 'get-application-by-id'],
    }),
    rejectPAM: builder.mutation({
      query: (body) => {
        return { url: '/api/v1/internal-service/pam/application/reject', method: 'PATCH', body };
      },
      invalidatesTags: (result, error) =>
        error ? [] : ['get-application', 'get-application-by-id'],
    }),
    withdrawPAM: builder.mutation({
      query: (body) => {
        return { url: '/api/v1/internal-service/pam/application/withdraw', method: 'POST', body };
      },
      invalidatesTags: (result, error) =>
        error ? [] : ['get-application', 'get-application-by-id'],
    }),
    enrolPAM: builder.mutation({
      query: (body) => {
        return { url: '/api/v1/internal-service/pam/application/enrol', method: 'PATCH', body };
      },
      invalidatesTags: (result, error) =>
        error ? [] : ['get-application', 'get-application-by-id'],
    }),
    carrerFieldPAM: builder.mutation({
      query: (body) => {
        return {
          url: '/api/v1/internal-service/pam/application/career-fields',
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (result, error) =>
        error ? [] : ['get-application', 'get-application-by-id'],
    }),
    durationPAM: builder.mutation({
      query: (body) => {
        return { url: '/api/v1/internal-service/pam/application/duration', method: 'PATCH', body };
      },
      invalidatesTags: (result, error) =>
        error ? [] : ['get-application', 'get-application-by-id'],
    }),
    hoursPerWeekPAM: builder.mutation({
      query: (body) => {
        return {
          url: 'api/v1/internal-service/pam/application/hours-per-week',
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (result, error) =>
        error ? [] : ['get-application', 'get-application-by-id'],
    }),
    internshipStartDatePAM: builder.mutation({
      query: (body) => {
        return {
          url: '/api/v1/internal-service/pam/application/internship-start-date',
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (result, error) =>
        error ? [] : ['get-application', 'get-application-by-id'],
    }),
    hideProfile: builder.mutation({
      query: (body) => {
        return {
          url: '/api/v1/internal-service/pam/application/hide',
          method: 'PATCH',
          body,
        };
      },
    }),
    unHideProfile: builder.mutation({
      query: (body) => {
        return {
          url: '/api/v1/internal-service/pam/application/unhide',
          method: 'PATCH',
          body,
        };
      },
    }),
    getApplicationActiveOffer: builder.query({
      query: (applicationIdsObj) => {
        const queryString = objectToQueryString(applicationIdsObj);

        return {
          url: `${activeOfferUrl}?${queryString}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetApplicationPAMQuery,
  useLazyGetApplicationPAMQuery,
  useGetApplicationPAMByIdQuery,
  useUpdateInternDobMutation,
  useRevertApplicationToSubmittedMutation,
  useConfirmEnrolmentReminderMutation,
  useBulkUpdateCheckMutation,
  useGetCareerFieldProjectsQuery,
  useUpdateProfileValuesMutation,
  useUpdateDetailsMutation,
  useCreateAdditionalQuestionsDataMutation,
  useUpdateAdditionalQuestionsMutation,
  useUpdateCertificationMutation,
  useUpdateEducationMutation,
  useDeleteCertificationMutation,
  useDeleteEducationMutation,
  useDeleteVolunteerMutation,
  useUpdateWorkExMutation,
  useDeleteWorkExMutation,
  useUpdateVolunteerMutation,
  useUpdatePortfolioMutation,
  useDeletePortfolioMutation,
  useApprovePAMMutation,
  useCarrerFieldPAMMutation,
  useRejectPAMMutation,
  useWithdrawPAMMutation,
  useEnrolPAMMutation,
  useDurationPAMMutation,
  useHoursPerWeekPAMMutation,
  useInternshipStartDatePAMMutation,
  useHideProfileMutation,
  useUnHideProfileMutation,
  useGetApplicationActiveOfferQuery,
  useCreateCertificationMutation,
  useCreateEducationMutation,
  useCreatePortfolioMutation,
  usePrefetch,
} = GetApplicationPAMApi;
export default GetApplicationPAMApi.reducer;
