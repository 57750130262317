import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';
import { onQueryStartedErrorToast } from 'Util/rtkQueryErrorHandler';

const getRemoteToolsUrl = '/common-services/remote-work-tools';
const getCompanyProjectsUrl = '/common-services/company-project/list';
const getInternApplicationSpecificDataUrl = '/api/v1/internal-service/offers';
const updateOfferUrl = '/api/v1/internal-service/offers';
const getInternDetailsToCreateOfferUrl = '/api/v1/internal-service/offers/intern';
const getCompaniesListUrl = '/api/v1/internal-service/host-company/get-list-dropdown';
const rejectOfferUrl = '/api/v1/internal-service/offers';
const calculateInternshipEndDateUrl = '/api/v1/internal-service/offers/calculate-end-date';
const checkPreviousInternshipExistsUrl = '/api/v1/internal-service/internship';
const offerTimelineUrl = 'api/v1/internal-service/offers';

export const OffersApi = createApi({
  reducerPath: 'offersApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['GetOffer', 'GetFeedbackDetail', 'GetInternDetail', 'GetOfferTimeline'],
  endpoints: (builder) => ({
    getOfferById: builder.query({
      query: ({ offerId }) => {
        return { url: `${updateOfferUrl}/get/${offerId}`, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
      providesTags: ['GetOffer'],
    }),

    getFeedbackDetail: builder.query({
      query: ({ offerId }) => {
        return { url: `${updateOfferUrl}/get-feedback-detail/${offerId}`, method: 'GET' };
      },
      onQueryStarted: onQueryStartedErrorToast,
      providesTags: ['GetFeedbackDetail'],
    }),

    getInternDetailsToCreateOffer: builder.query({
      query: (hostCompanyId) => {
        return { url: `${getInternDetailsToCreateOfferUrl}/${hostCompanyId}`, method: 'GET' };
      },
      providesTags: ['GetInternDetail'],
    }),
    getCompaniesList: builder.query({
      query: () => {
        return { url: getCompaniesListUrl, method: 'GET' };
      },
    }),
    getRemoteTools: builder.query({
      query: () => {
        return { url: getRemoteToolsUrl, method: 'GET' };
      },
    }),
    getInternApplicationSpecificData: builder.query({
      query: (offerId) => {
        return {
          url: `${getInternApplicationSpecificDataUrl}/${offerId}/application`,
          method: 'GET',
        };
      },
    }),
    getOfferBatchDetails: builder.query({
      query: ({ applicationId, irId }) => {
        return {
          url: `${getInternApplicationSpecificDataUrl}/application/${applicationId}/${irId}`,
          method: 'GET',
        };
      },
    }),
    getCompanyProjects: builder.query({
      query: (searchParams) => {
        let url = getCompanyProjectsUrl;
        if (searchParams) {
          const queryParams = new URLSearchParams(searchParams);
          url = `${url}?${queryParams}`;
        }
        return { url, method: 'GET' };
      },
    }),
    updateOffer: builder.mutation({
      query: ({ offerId, payload }) => {
        return { url: `${updateOfferUrl}/${offerId}`, method: 'PATCH', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetOffer']),
    }),
    createOffer: builder.mutation({
      query: ({ internId, payload }) => {
        return { url: `${updateOfferUrl}/${internId}`, method: 'POST', body: payload };
      },
    }),
    rejectOffer: builder.mutation({
      query: ({ offerId, payload }) => {
        return {
          url: `${rejectOfferUrl}/${offerId}/reject`,
          method: 'PATCH',
          body: payload,
        };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetOffer', 'GetOfferTimeline']),
    }),
    getInternshipEndDate: builder.query({
      query: ({ startDate, duration }) => {
        return {
          url: calculateInternshipEndDateUrl + `?start_date=${startDate}&duration=${duration}`,
          method: 'GET',
        };
      },
    }),

    checkPreviousInternshipExists: builder.query({
      query: ({ applicationId }) => {
        return {
          url: `${checkPreviousInternshipExistsUrl}/${applicationId}/check-previous-internship-exists`,
          method: 'GET',
        };
      },
    }),

    updateStatus: builder.mutation({
      query: (payload) => {
        return { url: `${updateOfferUrl}/update-offer-status`, method: 'POST', body: payload };
      },
      invalidatesTags: (result, error) => (error ? [] : ['GetOffer', 'GetOfferTimeline']),
    }),

    saveFeedback: builder.mutation({
      query: (payload) => {
        return {
          url: `${updateOfferUrl}/save-feedback`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: (result, error) =>
        error ? [] : ['GetOffer', 'GetFeedbackDetail', 'GetOfferTimeline'],
    }),

    sendReminder: builder.mutation({
      query: (payload) => {
        return {
          url: `${updateOfferUrl}/send-reminder`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    getOfferTimeline: builder.query({
      query: ({ offerId }) => {
        return {
          url: `${offerTimelineUrl}/${offerId}/offer-timeline`,
          method: 'GET',
        };
      },
      providesTags: ['GetOfferTimeline'],
      onQueryStarted: onQueryStartedErrorToast,
    }),
  }),
});

export const {
  useGetOfferByIdQuery,
  useGetFeedbackDetailQuery,
  useGetRemoteToolsQuery,
  useGetCompanyProjectsQuery,
  useGetInternApplicationSpecificDataQuery,
  useUpdateOfferMutation,
  useGetInternDetailsToCreateOfferQuery,
  useGetCompaniesListQuery,
  useGetOfferBatchDetailsQuery,
  useCreateOfferMutation,
  useRejectOfferMutation,
  useLazyGetInternshipEndDateQuery,
  useCheckPreviousInternshipExistsQuery,
  useSaveFeedbackMutation,
  useUpdateStatusMutation,
  useSendReminderMutation,
  useGetOfferTimelineQuery,
} = OffersApi;
export default OffersApi.reducer;
